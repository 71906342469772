import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getLanguageKey, language_config } from "../../utils/axios_config";
import { store } from "../../redux/store";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Link from '@mui/material/Link';
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from "../../utils/url_config";
import { footer_section } from "../../utils/types";
export default function BasicSelect() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  // const selectedLang = queryParams.get('lang') || localStorage.getItem('language') || 'ar';
   const selectedLang = 'ar';

  const [lang, setlang] = React.useState<string>(selectedLang);

  const handleChange = (event: SelectChangeEvent) => {
    // setlang(event.target.value as string);
    setlang('ar' as string);
    // localStorage.setItem('language', event.target.value);
    localStorage.setItem('language', 'ar');
    localStorage.removeItem('QuizQuestions');
    window.dispatchEvent(new Event('storage'));
    console.log('location.pathname', location.pathname);
    if(location.pathname === '/MyStuff' || location.pathname.includes('/question/') || location.pathname === '/QuizResult') {
      navigate('/MyValues');
    }
  };

  //Used for the dropdown button in the navigation menu, to be a trigger for Strapi content, i.e. when Spanish selected, switches to Spanish version, etc.
  React.useEffect(() => {
    if (localStorage.getItem("language") != null) {
      setlang(localStorage.getItem("language")!)
    } else {
      console.log("is null")
    }
  }, [])

  React.useEffect(() => {
    localStorage.setItem("language", lang!);
    window.dispatchEvent(new Event('storage'))

  }, [lang]);

  const [languageSwitcherData, setLanguageSwitcherData] = useState<footer_section>();

  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const result_footer = await axios.get(REACT_APP_api_base_url +
          "/api/footers?populate=deep&locale=" +
          localStorage.getItem("language")
        );
        setLanguageSwitcherData(result_footer.data.data[0].attributes);
      } catch (error) {
        console.error("Error fetching learn about data: ", error);
        try {
          const result_footer = await axios.get(REACT_APP_api_base_url +
            "/api/footers?populate=deep&locale=" +
            DEFAULT_LANGUAGE
          );
          setLanguageSwitcherData(result_footer.data.data[0].attributes);
        } catch (error) {
          console.error(
            "Error fetching learn about data with default locale: ",
            error
          );
        }
      }
    }
    fetchHeaderData();
  }, []);

  return (
    <Box sx={{ minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={lang}
        onChange={handleChange}
      >
        {languageSwitcherData?.Footer_Language?.Languages.map((item, index) => (
          [
            <MenuItem value={item.Lang_Code}>
                {index === 0 ? (
                  item.Language
                ) : (
                  <Link href={`https://www.inductiondecisionaid.org/Home?lang=${item.Lang_Code}`} target="_blank" style={{ textDecoration: 'none' }}>
                    {item.Language}
                  </Link>
                )}
          </MenuItem>
          ]))}
      </Select>
    </Box>
  );
}
