import LayoutNested from "../components/LayoutNested";
import "./pageStyle/MyValues.css"
//import "../ClashGrotesk_Complete/Fonts/WEB/css/clash-grotesk.css"
import React, { useRef,useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Divider, Grid, Container, ThemeProvider, Typography, Stack, Button } from "@mui/material";
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from "../utils/url_config";
import axios from "axios";
import { StyledEngineProvider } from "@mui/material/styles";
import {
  my_Values,
} from "../utils/types";
export default function MyValues() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedLang = queryParams.get('lang') || localStorage.getItem('language') || 'ar';
  let lang = selectedLang; 
  const url = `/question/${lang === 'ar' ? 1 : 8}`;
  const [PageTitlesData, setPageTitlesData] = useState<my_Values>();
  const [languageState, setLanguageState] = useState("ar");
  useEffect(() => {
    window.addEventListener("storage", () => {
      setLanguageState(localStorage.getItem("language") || "ar");
    });
  }, []);
  useEffect(() => {
  const fetchPageTitlesData = async () => {
      try {
        const result = await axios.get( REACT_APP_api_base_url +
          "/api/my-values-page-title?populate=deep&locale=" +
            localStorage.getItem("language")
        );
        setPageTitlesData(result.data);
      } catch (error) {
        console.error("Error fetching learn about data: ", error);
        try {
          const result = await axios.get( REACT_APP_api_base_url +
            "/api/my-values-page-title?populate=deep&locale=" +
              DEFAULT_LANGUAGE
          );
          setPageTitlesData(result.data);
        } catch (error) {
          console.error(
            "Error fetching learn about data with default locale: ",
            error
          );
        }
      }
    };
    fetchPageTitlesData();
  }, [languageState]);
  return (
    <StyledEngineProvider injectFirst>
      <LayoutNested>
        <Grid
            container
            columns={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
            bgcolor="#F4FCF0"
            pt="15rem"
            pb="15rem"
            className="my-value-main"
            >
            <Container maxWidth="sm">
            <Grid className="my-value-content">
              {PageTitlesData?.data.attributes.Title != null ? (
                  <Typography variant="h2" color="primary.main">
                     <div className="content" style={{ direction: 'initial', textAlign: 'right' }} dangerouslySetInnerHTML={{__html: PageTitlesData?.data.attributes.Title}}></div> 
                  </Typography>
                ) : null}

                <div>
                    <p className="QuizIntro">{PageTitlesData?.data.attributes.Content}</p>
                </div>
                <div>
                    <Link to={url} style={{ marginTop: '1rem', display: 'inline-block' }}>
                      <button className="Begin">
                        {PageTitlesData?.data.attributes.ButtonText}
                      </button>
                    </Link>
                </div>
                </Grid>
            </Container>
        </Grid>
      </LayoutNested>
    </StyledEngineProvider>
  );
}
