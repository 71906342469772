import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import axios from "axios";
import {Link} from "@mui/material";
import {
  global_API,
} from "../utils/types";
import { REACT_APP_api_base_url, DEFAULT_LANGUAGE } from "../utils/url_config";

const CookieBanner: React.FC = () => {
  const [open, setOpen] = useState(true);
  const [globalData, setGlobalData] = useState<global_API>();
  const [languageState, setLanguageState] = useState("en");

  useEffect(() => {
    window.addEventListener("storage", () => {
      setLanguageState(localStorage.getItem("language") || "en");
    });
  }, []);

  useEffect(() => {
    const fetchglobalData = async () => {
      try {
        const result = await axios.get(REACT_APP_api_base_url +
          "/api/global-translation-api?populate=deep"
        );
        setGlobalData(result.data);
      } catch (error) {
        console.error("Error fetching learn about data: ", error);
        try {
          const result = await axios.get(REACT_APP_api_base_url +
            "/api/global-translation-api?populate=deep&locale=" +
            DEFAULT_LANGUAGE
          );
          setGlobalData(result.data);
        } catch (error) {
          console.error(
            "Error fetching learn about data with default locale: ",
            error
          );
        }
      }
    };

    fetchglobalData();
  }, [languageState]);

  useEffect(() => {
    // Check if the cookie is set
    const isCookieSet = document.cookie.includes("cookieBannerClosed=true");

    // If the cookie is set, close the banner
    if (isCookieSet) {
      setOpen(false);
    }
  }, []);

  const handleClose = () => {
    // Set the cookie to expire in 30 days
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    document.cookie = "cookieBannerClosed=true; expires=" + expirationDate.toUTCString();

    setOpen(false);
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1300, // Adjust the z-index as needed
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="cookie-banner"
        disableScrollLock
        className="cookie-banner"
      >
        <DialogContent>
          <p>{globalData?.data.attributes.Cookies}</p>
          <Link onClick={handleClose} style={{ color: '#00653E', cursor: 'pointer' }} autoFocus>
            {globalData?.data.attributes.CookiesButton}
          </Link>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CookieBanner;
