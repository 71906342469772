// Question.tsx
import React, { useEffect, useState } from "react";
import LayoutNested from "../LayoutNested";
import { Slider } from "@mui/material";
import { styled } from "@mui/system";
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from "react-redux";
import { addToCategory } from "../../redux/slices/ratingSlice";
import { RootState } from "../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import "../../pages/pageStyle/MyValues.css";
import { StyledEngineProvider } from "@mui/material/styles";
import ProgressBar from "./ProgressBar";
import axios from "axios";
import { Box, Divider, Grid, Container, ThemeProvider, Typography, Stack, Button } from "@mui/material";
import { Buffer } from "buffer";
import { DEFAULT_LANGUAGE, REACT_APP_api_base_url } from "../../utils/url_config";
import { global_API, myValue_lastChoices } from "../../utils/types";
const CustomSlider = styled(Slider)({
    width: "437px",
    "& .MuiSlider-rail": {
        backgroundColor: "#0C3A25",
        width: "400px",
        height: "6px",
        opacity: 1,
    },
    "& .MuiSlider-track": {
        backgroundColor: "#0C3A25",
        opacity: 1,
    },
    "& .MuiSlider-thumb": {
        backgroundColor: "#0C3A25",
        width: "32px",
        height: "32px",
        opacity: 1,
    },
    "& .MuiSlider-mark": {
        backgroundColor: "#0C3A25",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        opacity: 1,
    },
    "& .MuiSlider-markLabel": {
        color: "#0C3A25", // Set the font color for the label
        fontFamily: "ClashGrotesk-Regular,sans-serif", // Set the font for the label
        fontWeight: "normal", // Set the font weight for the label
        fontSize: "14px", // Set the font size for the label
        top: "40px",
    },
    "& .MuiSlider-markActive": {
        backgroundColor: "#0C3A25",
    },
});


const Question = () => {
    // redux usage
    const dispatch = useDispatch();
    // page change
    const navigate = useNavigate();
    // update data in redux
    const categories = useSelector((state: RootState) => state.rating);
    const [question, setQuestion] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [questionTotal, setQuestionTotal] = useState(0);
    const { id } = useParams();
    const [sliderValue, setSliderValue] = useState(1);
    const [languageState, setLanguageState] = useState('ar');
    const [questionSr, setQuestionSr] = useState(1);
    const [lastQuestionChoices, setLastQuestionChoices] = useState<myValue_lastChoices>();
    const [globalData, setGlobalData] = useState<global_API>();

    useEffect(() => {
        window.addEventListener('storage', () => {
            setLanguageState(localStorage.getItem('language') || 'ar')
        });
    }, []);

    // reset slider value to 1 when a new question is loaded
    useEffect(() => {
        setSliderValue(1);
    }, [id]);

    //existing imageToBase64 function
    async function imageToBase64(src) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';

                xhr.onload = () => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;

                    reader.readAsDataURL(xhr.response);
                };

                xhr.open('GET', src);
                xhr.send();
            });
        }

      // update question content when id changes
      useEffect(() => {
        const fetchQuestion = async () => {
            const response = await fetch(
                process.env.REACT_APP_api_base_url + `/api/my-values-questions/${id}?populate=deep`
                );
            const responseTotal = await fetch(
                process.env.REACT_APP_api_base_url + `/api/my-values-questions/`
                );
           
            const data = await response.json();
            const dataTotal = await responseTotal.json();
            setQuestion(data.data);
            setQuestionTotal(dataTotal.data.length);
           const base64Image = await imageToBase64(data.data.attributes.question_detail[0].Icon.data.attributes.url);

            const categoryLabels: (keyof typeof categories)[] = [
                "leastImportant",
                "lessImportant",
                "important",
                "mostImportant",
                ];
            const defaultCategory = categoryLabels[0]; // "leastImportant"
            dispatch(addToCategory({ category: defaultCategory,questionNum:data.data.id, questionText: data.data.attributes.question_detail[0].question_content, questionIcon: base64Image as string,isChoice: false }));
            setSliderValue(1);
        }
        fetchQuestion();
        let currentStepKey = questionsByLanguage[languageState].indexOf(parseInt(id!));
        console.log('currentStepKey', currentStepKey);
        if(currentStepKey >= 0){
            setQuestionSr(parseInt(currentStepKey) + 1);
            setCurrentStep(parseInt(currentStepKey) + 1);
        }
        
    }, [id]);

    useEffect(() => {

        const fetchglobalData = async () => {
            try {
              const result = await axios.get( REACT_APP_api_base_url +
                "/api/global-translation-api?populate=deep"
              );
              setGlobalData(result.data);
            } catch (error) {
              console.error("Error fetching learn about data: ", error);
              try {
                const result = await axios.get( REACT_APP_api_base_url +
                  "/api/global-translation-api?populate=deep"
                );
                setGlobalData(result.data);
              } catch (error) {
                console.error(
                  "Error fetching learn about data with default locale: ",
                  error
                );
              }
            }
          };

        const fetchLastQuestionChoices = async () => {
            try {
              const result = await axios.get( REACT_APP_api_base_url +
                "/api/my-values-last-question-choice?populate=deep"
              );
              setLastQuestionChoices(result.data);
            } catch (error) {
              console.error("Error fetching questions choices data: ", error);
              try {
                const result = await axios.get( REACT_APP_api_base_url +
                  "/api/my-values-last-question-choice?populate=deep"
                );
                setLastQuestionChoices(result.data);
              } catch (error) {
                console.error(
                  "Error fetching questions choices with default locale: ",
                  error
                );
              }
            }
          };  
          fetchLastQuestionChoices();
          fetchglobalData();
        }, [languageState]);

    // update results array when user click the slider
      const handleSliderChange = async (value: number | number[]) => {
        if (question.id === 6 ) {
            const categoryLabels: (keyof typeof categories)[] = [
                "choiceOne",
                "choiceTwo",
                "choiceThree",
                "choiceFour",
                "choiceFive",
                ];

            const category = categoryLabels[value as number - 1];
            dispatch(addToCategory({ category, questionNum:question.id, questionText: question.attributes.question_detail[0].question_content,
                questionIcon: await imageToBase64(question.attributes.question_detail[0].Icon.data.attributes.url) as string, isChoice: true }));
        } else {
            const categoryLabels: (keyof typeof categories)[] = [
                "leastImportant",
                "lessImportant",
                "important",
                "mostImportant",
                ];

            const category = categoryLabels[value as number - 1];
            dispatch(addToCategory({ category, questionNum:question.id,questionText: question.attributes.question_detail[0].question_content,
                questionIcon: await imageToBase64(question.attributes.question_detail[0].Icon.data.attributes.url)as string, isChoice: false }));
        }
        setSliderValue(value as number);
    };

    let questionsByLanguage = [];
    questionsByLanguage['ar'] = [1,2,3,4,5,6, 'quiz'];
    let lastQuest = {'ar': 6}
    
    const handleNext = () => {
        let currentStepKey = questionsByLanguage[languageState]?.indexOf(parseInt(id!));
        const nextKey = parseInt(currentStepKey) + 1;
        setQuestionSr(parseInt(currentStepKey) + 1);
        setCurrentStep(parseInt(currentStepKey) + 1);

        if (questionsByLanguage[languageState][nextKey] !== 'quiz') {
            setQuestionSr(nextKey);
            const nextId = questionsByLanguage[languageState][nextKey];

            navigate(`/question/${nextId}`);
            setCurrentStep(nextId);

        } else {
            navigate(`/QuizResult`);
        }

    };

    // previous button
    const handlePrevious = () => {
        let currentStepKey = questionsByLanguage[languageState].indexOf(parseInt(id!));
        setQuestionSr(parseInt(currentStepKey) + 1);
        setCurrentStep(parseInt(currentStepKey) + 1);
        const prevKey = parseInt(currentStepKey) - 1;

        if (prevKey !== -1) {
            setQuestionSr(prevKey);
            const prevId = questionsByLanguage[languageState][prevKey];

            navigate(`/question/${prevId}`);
            setCurrentStep(prevId); // Update the currentStep state

        } else {
            navigate(`/MyValues`);
        }
    };

    const lang = languageState;

    return (
        <StyledEngineProvider injectFirst>
            <LayoutNested>
                <Grid
                    container
                    columns={{ xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }}
                    bgcolor="#F4FCF0"
                    pt="3rem"
                    pb="4rem"
                    className="my-value-main"
                >
                    <Container maxWidth="md">
                        {question && (
                            <>
                                <Grid mb="2rem">
                                    <ProgressBar currentStep={currentStep} totalSteps={questionTotal} />
                                </Grid>

                                <Box component="span" className="question-id" bgcolor="#0C3A25" sx={{ p: 2, borderRadius: '100px', color: '#ffffff' }}>
                                    {questionSr}
                                </Box>

                                <Container maxWidth="sm">
                                    <Typography variant="h5" letterSpacing="1.5px" mb="1.5rem" fontSize="18px" color="primary.main">
                                       {question.attributes.question_detail[0].intro_sentence}
                                    </Typography>

                                    <Typography variant="h3" fontSize="2rem" color="primary.main">
                                        {question.attributes.question_detail[0].question_content}
                                    </Typography>
                                </Container>
                                {question.id === 6 ?
                                    <CustomSlider
                                        className="step-slider step-slider-v2 last-question"
                                        value={sliderValue}
                                        step={null}
                                        marks={[
                                            { value: 1, label: lastQuestionChoices?.data.attributes.choiceOne, },
                                            { value: 2, label: lastQuestionChoices?.data.attributes.choiceTwo },
                                            { value: 3, label: lastQuestionChoices?.data.attributes.choiceThree },
                                            { value: 4, label: lastQuestionChoices?.data.attributes.choiceFour },
                                            { value: 5, label: lastQuestionChoices?.data.attributes.choiceFive },
                                        ]}
                                        min={1}
                                        max={5}
                                        onChange={(event, value) => handleSliderChange(value)}
                                    /> : <CustomSlider
                                        className="step-slider"
                                        value={sliderValue}
                                        step={null}
                                        marks={[
                                            { value: 1, label: globalData?.data.attributes.leastImportant, },
                                            { value: 2 },
                                            { value: 3 },
                                            { value: 4, label: globalData?.data.attributes.mostImportant },
                                        ]}
                                        min={1}
                                        max={4}
                                        onChange={(event, value) => handleSliderChange(value)}
                                    />}

                                <p className="drag-text">
                                   {question.attributes.question_detail[0].slider_description}
                                </p>
                                <div className="pre-next-container">
                                    <button onClick={handlePrevious} className="Previous-circle"><span><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.00009 0L0.590088 1.41L5.17009 6L0.590088 10.59L2.00009 12L8.00009 6L2.00009 0Z" fill="#0C3A25" />
                                    </svg>
                                    </span> {globalData?.data.attributes.backButton}</button>
                                    <button onClick={handleNext} className="Next-circle"><span><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.99991 12L7.40991 10.59L2.82991 6L7.40991 1.41L5.99991 -1.23266e-07L-8.72135e-05 6L5.99991 12Z" fill="#0C3A25" />
                                    </svg>
                                    </span>{globalData?.data.attributes.nextButton}</button>
                                </div>

                                <div className="back_btn">
                                    <Link href="/MyChoices">{globalData?.data.attributes.comeBackLater}</Link>
                                </div>

                            </>
                        )}
                    </Container>
                </Grid>
            </LayoutNested>
        </StyledEngineProvider>
    );
};

export default Question;
