import React from "react";
import { Button as MUIButton } from "@mui/material";
function isValidUrl(str) {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(str);
}

const MenuButton = ({ children, routeName, onClick }: any) => {
  const pathlength = window.location.pathname.split("/").length;
  const pathName = window.location.pathname.split("/")[pathlength - 1];
  const active = pathName === routeName;

  const handleButtonClick = () => {
    // Open the link in a new tab when not an internal route
    if (isValidUrl(routeName)) {
      window.open(routeName, "_blank");
    } else {
      // Navigate within the app for internal routes
      onClick();
    }
  };

  return (
    <MUIButton
      sx={{
        textTransform: "none",
        borderRadius: 8,
        paddingX: 3,
        paddingY: 1,
        border: "2px solid #0c3a25",
        color: active ? "white" : "#0c3a25",
        backgroundColor: active ? "#0c3a25" : "white",
        boxShadow: "none",
        ":hover": { color: "white" },
      }}
      className={active ? "active-btn" : ""}
      variant="contained"
      onClick={handleButtonClick}
    >
      {children}
    </MUIButton>
  );
};

export default MenuButton;